@import "../../sass/variables.scss";

.hero-image {
    background-color: $primary;
    height: 300px;
    margin: -60px auto 0px auto;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
        height: 400px;
    }
    @media (min-width: map-get($grid-breakpoints, "md")) {
        height: 500px;
    }
    @media (min-width: map-get($grid-breakpoints, "xxl")) {
        height: 600px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    text-align: center;
}

.h1-hero {
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-weight: 300;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1.25rem;
    margin-top: 68px;
    margin-bottom: 0;
    background-color: $light;
    color: black;
    mix-blend-mode: screen;
}

.h2-hero {
    font-family: $font-family-sans-serif;
    text-transform: lowercase;
    text-align: center;
    font-weight: 300;
    margin: 0 auto;
    padding: 0.5rem 0;
}

@media (max-width: 374px) {
    .h2-hero {
        font-size: calc(1.125rem + 0.9vw);
    }
}

@media (min-width: 375px) and (max-width: 1199px) {
    .h2-hero {
        font-size: calc(1.25rem + 0.9vw);
    }
}

@media (min-width: 1200px) {
    .h1-hero {
        font-size: 3.5rem;
    }
}


.quantity-input-group {
    width: 130px;
    margin-right: 10px;
    input {
        text-align: center;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
}

.add-to-basket-container {
    display: flex;
}

.contact-us {
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
}