@import "../../sass/variables.scss";

.sign-in-form-container {
    max-width: 320px;
}

.sign-in-error-message {
    font-size: 0.9rem;
}

.password-reset-text {
    font-size: 0.8rem;
}
