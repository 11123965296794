// @import '../../sass/variables.scss';

.cookie-jar {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 1rem;
}

.accept-button {
    margin-left: 1rem;
}