$cinnamon-satin: rgb(238, 192, 203);
$cornflower-blue: #779be7ff;
$eggshell: #f1e9dbff;
$black-olive: #423e37ff;
$blue-munsell: #2c8c99ff;
$pastel-peach: #f7dfc2ff;
$snow: #fdf7faff;
$powder-blue: #a8dcd9ff;
$hookers-green: #517664ff;
$ming: #387780ff;
$emerald-green: #00412d;
$olive-green: #4e533b;

//Color palette
$copper-rose: #9f6f75ff;
$dark-sienna: #3c0919ff;
$platinum: #eadedaff;
$rifle-green: #4a533bff;
$charleston-green: #2c302eff;

$primary: $dark-sienna;
$secondary: $copper-rose;
$light: $platinum;
$dark: $charleston-green;
$min-contrast-ratio: 3;

$bs-body-color: $dark;

// Fonts
$font-family-sans-serif: "Montserrat", sans-serif;

// Cards
$card-border-width: 0px;
$card-border-radius: 0px;

// Headings
$headings-font-family: "Lora", serif;
$headings-margin-bottom: 0.5rem;
$headings-font-style: null !default;
$headings-font-weight: 600;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

//Forms
$form-label-margin-bottom: 0.25rem;

//Buttons
$btn-font-weight: 500;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";