.register {
    min-height: calc(100vh - 128px);
}

.sign-up-form-container {
    max-width: 320px;
}

.sign-up-error-message {
    font-size: 0.9rem;
}
