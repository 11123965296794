@import "../../sass/variables.scss";

.basket-container {
    position: fixed;
    z-index: 1050;
    background-color: rgba($primary, 0.5);
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    opacity: 100;
    visibility: visible;
}

.basket-container-hidden {
    opacity: 0;
    visibility: hidden;
}

.basket {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    right: 0px;
    height: 100%;
    top: 0px;
    width: 100%;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
        width: 375px;
    }
    background-color: $light;
    padding-top: 1rem;
}

.basket-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
        margin: 0;
    }
}

.basket-contents {
    flex-grow: 1;
}

.basket-total {
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
}

.checkout-button {
    margin-bottom: 1rem;
}

.basket-item-container {
    margin-bottom: 1rem;
}

.basket-image-container {
    height: 80px;
    margin: 0;
    padding: 0;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.quantity-select-container {
    padding: 0.5rem 0 0 0;
}
