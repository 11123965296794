@import "../../sass/variables.scss";

.product-card-subtitle {
        font-family: $font-family-sans-serif !important;
        font-weight: $font-weight-normal;
}

.product-card-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
}

.blur-image {
        filter: blur(10px);
}

.modal-title {
        flex-grow: 1;
}

.modal-price {
        line-height: 1.5rem;
}

.ingredients-list-inci{
        font-size: 0.8rem;
}