.product-image {
    background-position: "center";
    background-repeat: "no-repeat";
    background-size: "cover";
    background-blend-mode: "normal";
    height: 500px;
}

.our-story {
    min-height: calc(100vh - 128px);
}

.our-story-content{
    margin-top: 0.5rem;
}