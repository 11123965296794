@import "../../sass/variables.scss";

.navbar-wrapper {
    background-color: rgba($white, $alpha:.8) !important;
    backdrop-filter: blur(10px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    z-index: 999;
}

.navbar-container {
    max-width: 960px;
}

.dropdown-toggle::after{
    display: none!important;
}

.nav-mobile-only {
    flex-grow: 0;
}

.basket-number {
    font-size: 1rem;
    font-style: initial;
    position: absolute;
    top: -0.5rem;
    right: -0.6rem;
}