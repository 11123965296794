@import './variables.scss';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import '~react-toastify/scss/main';

html, body, #root {
    min-height: 100vh;
}

body {
    overflow-y: scroll;
}

#root{
    display: flex;
    flex-direction: column;
}

.hash-offset {
    padding: 80px 0 0 0!important;
    margin: -60px 0 0 0!important;
}

.max-readable-width {
    max-width: 620px !important;
}

.btn {
    text-transform: uppercase;
    letter-spacing: 0.1rem !important;
}

.navbar-brand {
    font-size: 1.75rem !important;
}

.card-subtitle {
    font-family: $font-family-sans-serif;
}

.site-content {
    flex-grow: 1;
}

.basket-visible {
    overflow-y: hidden;
}

.form-field-email {
    width: 300px;
}


.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef!important;
    opacity: 1;
}

:root {
    --toastify-color-success: #{$secondary};
}

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack