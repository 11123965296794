.checkout-image-container {
    height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.checkout-image {
    width: 100%;
    object-fit: cover;
    object-position: center bottom;
}