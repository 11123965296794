@import "../../sass/variables.scss";

.newsletter-container {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
}

.form-field-name {
    max-width: 250px;
}

.newsletter-button {
    background-color: $light;
    color: $primary;
}

.newsletter-message {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
    min-height: 1rem;
}