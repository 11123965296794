.footer {
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    min-height: 50px;
}

.footer-container {
    width: 100%;
}

.company-details {
    flex-grow: 1;
}

.privacy-policy-link {
    flex-grow: 0;
}

.footer-details > p,
.privacy-policy-link > p {
    margin: 0;
    font-size: 0.9rem;
}
